import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 251.000000 351.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,351.000000) scale(0.100000,-0.100000)" >
<path d="M184 3488 c3 -13 15 -75 26 -138 39 -213 123 -421 248 -610 68 -104
258 -296 357 -361 155 -101 340 -179 535 -224 201 -47 359 -100 510 -172 152
-72 158 -74 50 -10 -36 21 -56 40 -60 57 -4 15 -15 26 -30 30 -21 5 -25 13
-28 48 -2 25 1 42 7 42 7 0 11 54 11 160 0 105 -4 160 -11 160 -6 0 -9 29 -7
80 1 44 6 80 10 80 14 0 9 60 -4 60 -7 1 -3 6 9 13 18 10 20 15 9 30 -15 20
-32 22 -39 5 -2 -7 -8 -9 -13 -5 -9 9 13 27 33 27 6 0 19 -7 28 -16 9 -9 33
-28 53 -42 20 -13 41 -31 45 -38 4 -8 18 -19 30 -25 31 -15 -46 58 -113 108
-156 116 -345 198 -575 248 -469 103 -807 254 -1018 456 -34 33 -63 59 -64 59
-2 0 -1 -10 1 -22z m1543 -694 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z m78 -748 c19 -8 35 -19 35 -25 0 -5 -12 -3 -27 5 -16 7 -33 14 -40 14
-6 0 -15 5 -18 10 -9 14 10 12 50 -4z"/>
<path d="M1850 2690 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M1854 2658 c9 -5 23 -5 32 0 12 6 7 9 -16 9 -23 0 -28 -3 -16 -9z"/>
<path d="M2042 2546 c7 -8 15 -12 17 -11 5 6 -10 25 -20 25 -5 0 -4 -6 3 -14z"/>
<path d="M2101 1078 c-45 -98 -108 -190 -181 -265 -33 -35 -54 -63 -46 -63 7
0 33 22 57 50 26 30 46 45 50 39 5 -8 8 -8 13 0 4 6 5 15 2 20 -8 12 23 23 45
16 12 -4 22 1 29 15 9 17 19 21 53 18 38 -3 42 -5 40 -28 -2 -21 -1 -23 8 -7
5 9 15 17 21 17 6 0 3 -12 -7 -26 -9 -14 -14 -28 -11 -31 8 -9 65 88 72 123 3
18 1 48 -5 66 -11 32 -85 128 -99 128 -4 0 -22 -33 -41 -72z m-53 -148 c-22
-30 -35 -41 -22 -17 9 16 36 47 41 47 2 0 -6 -13 -19 -30z m22 -12 c0 -19 -18
-44 -25 -36 -4 3 0 15 7 27 15 24 18 26 18 9z"/>
<path d="M210 449 c0 -12 8 -28 18 -36 16 -14 16 -14 0 -9 -12 3 -18 0 -18 -9
0 -8 9 -15 20 -15 23 0 28 37 8 59 -10 11 -10 13 0 7 7 -4 12 -1 12 8 0 9 -9
16 -20 16 -14 0 -20 -7 -20 -21z"/>
<path d="M300 425 c0 -25 5 -45 10 -45 6 0 10 20 10 45 0 25 -4 45 -10 45 -5
0 -10 -20 -10 -45z"/>
<path d="M360 425 c0 -40 3 -45 22 -45 17 0 19 3 10 12 -7 7 -12 27 -12 45 0
18 -4 33 -10 33 -5 0 -10 -20 -10 -45z"/>
<path d="M434 439 c3 -17 6 -38 6 -45 0 -23 27 -16 34 9 9 33 7 67 -3 67 -5 0
-12 -10 -14 -22 l-4 -23 -2 23 c0 12 -6 22 -12 22 -8 0 -9 -11 -5 -31z"/>
<path d="M530 425 c0 -33 4 -45 15 -45 8 0 15 5 15 10 0 6 -5 10 -12 10 -9 0
-9 3 0 12 8 8 9 15 1 25 -8 9 -8 13 0 13 6 0 11 5 11 10 0 6 -7 10 -15 10 -11
0 -15 -12 -15 -45z"/>
<path d="M600 426 c0 -48 15 -63 23 -23 l4 22 2 -22 c0 -13 6 -23 12 -23 6 0
9 17 7 43 -2 35 -7 43 -25 45 -21 3 -23 -1 -23 -42z"/>
<path d="M698 459 c-2 -6 -2 -26 0 -45 3 -25 9 -34 23 -34 14 0 19 7 19 25 0
14 -5 25 -11 25 -6 0 -9 -7 -5 -15 3 -8 1 -15 -4 -15 -6 0 -10 12 -10 26 0 19
4 25 15 20 9 -3 15 0 15 9 0 17 -37 20 -42 4z"/>
<path d="M792 428 c2 -35 7 -43 23 -43 22 0 30 24 21 61 -4 15 -14 24 -27 24
-18 0 -20 -5 -17 -42z m25 -25 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2
-26 0 -35z"/>
<path d="M880 425 c0 -29 4 -45 13 -45 8 0 11 14 9 45 -2 25 -8 45 -13 45 -5
0 -9 -20 -9 -45z"/>
<path d="M960 424 c0 -41 2 -45 22 -42 39 5 36 88 -2 88 -17 0 -20 -7 -20 -46z
m27 -11 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z"/>
<path d="M1100 425 c0 -41 2 -45 25 -45 23 0 25 4 25 45 0 41 -2 45 -25 45
-23 0 -25 -4 -25 -45z m27 -12 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21
0 -30z"/>
<path d="M1200 448 c0 -13 -3 -32 -6 -44 -5 -17 -1 -21 23 -21 28 0 29 2 23
41 -3 28 -10 42 -22 44 -13 3 -18 -3 -18 -20z"/>
<path d="M1287 428 c3 -24 7 -45 9 -47 7 -8 11 4 17 47 4 36 2 42 -13 42 -15
0 -17 -6 -13 -42z"/>
<path d="M1365 443 c-4 -16 -8 -35 -9 -44 -2 -11 5 -16 24 -16 20 0 27 5 25
16 -2 9 -4 28 -4 44 -1 17 -7 27 -16 27 -8 0 -17 -12 -20 -27z"/>
<path d="M1500 453 c0 -9 8 -25 17 -35 16 -18 16 -18 0 -12 -18 7 -22 -3 -9
-24 18 -27 40 -2 40 44 -1 39 -4 44 -25 44 -15 0 -23 -6 -23 -17z"/>
<path d="M1592 428 c2 -35 7 -43 23 -43 16 0 21 8 23 43 2 23 -1 42 -7 42 -5
0 -12 -19 -14 -42 l-4 -43 -1 43 c-1 23 -7 42 -13 42 -6 0 -9 -17 -7 -42z"/>
<path d="M1684 448 c3 -13 6 -33 6 -45 0 -16 6 -23 20 -23 17 0 20 7 20 45 0
42 -2 45 -26 45 -22 0 -25 -3 -20 -22z m31 -38 c3 -5 1 -10 -4 -10 -6 0 -11 5
-11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z"/>
<path d="M434 322 c3 -6 0 -13 -6 -16 -7 -3 -4 -5 6 -3 21 2 25 19 5 26 -6 2
-9 -1 -5 -7z"/>
<path d="M372 298 c-6 -16 -16 -28 -22 -28 -21 0 -6 -45 15 -47 11 -2 14 0 8
3 -19 8 -16 34 5 42 14 5 17 13 12 32 l-7 25 -11 -27z"/>
<path d="M633 294 c-7 -7 -13 -26 -12 -41 l1 -28 14 34 c17 38 15 56 -3 35z"/>
<path d="M501 253 c-13 -27 -4 -30 16 -5 14 16 14 16 9 0 -3 -10 -1 -18 4 -18
6 0 10 4 10 9 0 5 3 16 6 25 10 25 -33 15 -45 -11z"/>
<path d="M732 268 c-16 -16 -15 -38 2 -38 9 0 12 7 9 19 -3 13 1 21 14 24 13
3 14 5 3 6 -9 0 -21 -4 -28 -11z"/>
<path d="M842 255 c-7 -15 -8 -25 -2 -25 6 0 10 7 10 15 0 7 8 19 18 24 14 9
15 10 1 11 -9 0 -21 -11 -27 -25z"/>
<path d="M946 249 c-8 -16 -11 -29 -6 -29 5 0 12 9 15 20 3 11 10 20 15 20 5
0 7 -7 4 -15 -4 -8 -1 -15 5 -15 6 0 11 7 11 15 0 8 1 18 3 22 1 3 -6 7 -15 9
-11 1 -23 -9 -32 -27z"/>
<path d="M1072 255 c-7 -15 -8 -25 -2 -25 6 0 10 7 10 15 0 9 10 20 23 25 21
8 21 9 1 9 -13 1 -25 -8 -32 -24z"/>
<path d="M1186 265 c-3 -9 -6 -22 -5 -28 0 -7 5 -1 10 12 5 13 8 26 5 28 -2 2
-6 -3 -10 -12z"/>
<path d="M1287 274 c-8 -8 3 -34 14 -34 5 0 9 9 9 20 0 19 -11 26 -23 14z"/>
<path d="M1400 265 c-12 -14 -6 -35 10 -35 4 0 6 10 2 22 -4 17 -2 20 11 15
10 -4 17 -2 17 3 0 15 -26 12 -40 -5z"/>
<path d="M1464 265 c3 -9 0 -15 -9 -15 -8 0 -15 -4 -15 -9 0 -5 9 -7 20 -4 21
6 29 43 9 43 -6 0 -9 -7 -5 -15z"/>
<path d="M320 165 c0 -13 73 -15 600 -15 527 0 600 2 600 15 0 13 -73 15 -600
15 -527 0 -600 -2 -600 -15z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
